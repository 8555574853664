<template xmlns="http://www.w3.org/1999/html">
  <div v-if="showPopupPrimary" class="popup-overlay">
    <div class="bank-detail primary popup-content">
        <p class="title">Оновлення реквізитів для оплати на UKASKO з 21.02</p>
        <div class="scroll-bar">
          <p class="text-block">Шановні партнери!</p>
          <p class="text-block">З 21 лютого 2025 року на платформі UKASKO діятимуть єдині реквізити для оплати через ТОВ "ЮІНШУР" у ПриватБанку:</p>
          <p class="text-block">Укладання договору співпраці з UKASKO для підготовки до змін в ЗУ "Про страхування", який починає діяти з 01.01.2025 року</p>
          <ul class="custom-list romb text-block">
            <li>Найменування отримувача: ТОВ ЮІНШУР</li>
            <li>Код отримувача: 45581136</li>
            <li>IBAN: UA773052990000026003006118349</li>
            <li>Назва банку: АТ КБ «ПриватБанк»</li>
            <li>МФО: 305299</li>
            <li>Призначення платежу: <span class="red">за оформлення страхових полісів ##номерВашогоКабінету</span></li>
          </ul>
          <p class="text-block">Ці платежі будуть по коду ##номерВашогоКабінету автоматично нараховуватись на Ваш кабінет</p>
          <p>Зміни стосуються всіх варіантів оплати, зокрема:</p>
          <ul class="custom-list ok text-block">
            <li>Рахунків-фактур</li>
            <li>Дебіторської заборгованості</li>
            <li>LiqPay</li>
            <li>Оплат за реквізитами</li>
            <li>Оплати частинами через Monobank та ПриватБанк</li>
          </ul>
          <p class="text-block">До кінця перехідного періоду оплати приймаються як від клієнтів, так і від агентів, без обмежень за сумою.</p>
          <p>Прохання більше не сплачувати на ТОВ "ЮІНШУР"</p>
          <p>Укрсиббанку та на ФОП Ярова Олена Миколаївна.</p>
          <p><span class="red">Незабаром ці рахунки будуть закриті.</span></p>
          <p class="text-block">
            Також ми аналізуємо можливість отримання QR-кодів та терміналів (включаючи термінали в телефоні) для зручних оплат від клієнтів фізичних бізнесів. Тому повідомте, будь ласка, у свій чат, якщо для Вас це актуально.
          </p>
          <p class="text-block bold">
            З 31 березня 2025 року завершується перехідний період, і всі агенти мають обрати формат співпраці:
          </p>
          <ul class="custom-list romb text-block">
            <li>ФОП/Юридична особа.</li>
            <li>Фізична особа – комісійна винагорода буде меншою, оскільки платформа утримуватиме податок відповідно до законодавства України (точний % податку наразі уточнюється).</li>
          </ul>
          <p>Ці зміни пов’язані з необхідністю приведення процесу виплат у відповідність до вимог чинного законодавства</p>
        </div>
        <p class="title text-block">Планую працювати, як:</p>
        <div class="row bottom-block">
          <div class="col-6" align="right">
            <button class="btn btn-blue left-btn" style="display: block" @click="handleFop()">
              ФОП/ЮО
            </button>
          </div>
          <div class="col-6">
            <button class="btn btn-orange right-btn" @click="handleFiz" style="display: block">ФІЗ ОСОБА</button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import VueCookie from 'vue-cookie';
import axios from "axios";
import _ from "lodash";

export default {
  name: "ModalBankDetailPrimary",
  data() {
    return {
      showPopupPrimary: false,
    };
  },
  computed: {
    currentUser(){
      return this.$store.getters.USER_INFO;
    }
  },
  mounted() {
    if(this.isMainUser()){
      let data = this.getData();
      this.showPopupPrimary = (data == false) ? true : false;
    }
  },
  methods: {
    putRequest(url,params){
      axios.put(url,
         params
      ).then(({data}) => {
        if(data.success){
          this.$alert('Збережено');
        }else{
          this.$alert('Сталась помилка, спробуйте пізніше...');
        }
      });
    },
    getRequest(url,params){
      axios.get(url,{params: params}
      ).then(({data}) => {
        this.showPopupPrimary = !data.success;
      });
    },
    getData(){
      let params = {
        user_id: this.currentUser.id,
      };
      this.getRequest('api/user-details/get',params);
    },
    handleFop() {
      this.showPopupPrimary = false;
      let params = {
        user_id: this.currentUser.id,
        status: "Fop/Legal",
      };
      this.putRequest('api/user-details/update',params);
    },
    handleFiz() {
      this.showPopupPrimary = false;
      let params = {
        user_id: this.currentUser.id,
        status: "Fiz",
      };
      this.putRequest('api/user-details/update',params);
    },
    isMainUser() {
      return !!! this.$store.getters.USER?.user_parent_id;
    },
  }
};
</script>

<style scoped>
.bank-detail.primary.popup-content{
  width: 446px;
  height: 455px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 141px;
}

.bank-detail.primary.popup-content .scroll-bar{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
  scrollbar-color: #cfcfcf white;
  scrollbar-width: thin;
}
.bank-detail.primary.popup-content p {
  text-align: justify;
  margin-bottom: 0px;
}
.bank-detail.primary.popup-content p.title{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.bank-detail.primary.popup-content ul > li{
  text-align: justify;
}
.bank-detail.primary.popup-content span.red{
  color: red;
}
.bank-detail.primary.popup-content .text-block{
  margin-bottom: 10px;
}
.bank-detail.primary.popup-content .text-block.bold {
  font-weight: bold;
}
.bank-detail.primary.popup-content .custom-list {
  list-style-type: none; /* Убираем стандартные маркеры */
  padding-left: 20px; /* Отступ для маркеров */
}

.bank-detail.primary.popup-content .custom-list li {
  position: relative;
  padding-left: 20px; /* Отступ для ромбика */
}
.bank-detail.primary.popup-content .custom-list.romb li::before {
  content: "◆";
  color: blue;
  font-size: 1.2em;
  position: absolute;
  left: 0;
  top: 0;
}
.bank-detail.primary.popup-content .custom-list.ok li::before {
  content: "✅";
  color: green;
  font-size: 0.8em;
  position: absolute;
  left: 0;
  top: 0;
}
.bank-detail.primary.popup-content .left-btn, .right-btn{
  width: 147px;
  border-color: transparent;
}

.bank-detail.primary.popup-content .left-btn:hover{
  background: inherit;
  color: inherit;
  border-color: grey;
  transition: border 0.5s ease-in-out;
}
.bank-detail.primary.popup-content .right-btn:hover{
  background: inherit;
  color: inherit;
  border-color: grey;
  transition: border 0.5s ease-in-out;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-button {
  display: inline-block;
  margin: 10px 0;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.popup-button:hover {
  background: #0056b3;
}

.close-button {
  display: inline-block;
  margin: 10px 0;
  padding: 10px 20px;
  background: #6c757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}
</style>
