
<template xmlns="http://www.w3.org/1999/html">
  <div v-if="showPopupSub" class="popup-overlay">
    <div class="bank-detail primary popup-content">
      <p class="title">Оновлення реквізитів для оплати на UKASKO з 21.02</p>
      <div class="scroll-bar">
        <p class="text-block">Шановні партнери!</p>
        <p class="text-block">З 21 лютого 2025 року на платформі UKASKO діятимуть єдині реквізити для оплати через ТОВ "ЮІНШУР" у ПриватБанку:</p>
        <p class="text-block">Укладання договору співпраці з UKASKO для підготовки до змін в ЗУ "Про страхування", який починає діяти з 01.01.2025 року</p>
        <ul class="custom-list romb text-block">
          <li>Найменування отримувача: ТОВ ЮІНШУР</li>
          <li>Код отримувача: 45581136</li>
          <li>IBAN: UA773052990000026003006118349</li>
          <li>Назва банку: АТ КБ «ПриватБанк»</li>
          <li>МФО: 305299</li>
          <li>Призначення платежу: <span class="red">за оформлення страхових полісів ##номерВашогоКабінету</span></li>
        </ul>
        <p class="text-block">Ці платежі будуть по коду ##номерВашогоКабінету автоматично нараховуватись на Ваш кабінет</p>
        <p>Зміни стосуються всіх варіантів оплати, зокрема:</p>
        <ul class="custom-list ok text-block">
          <li>Рахунків-фактур</li>
          <li>Дебіторської заборгованості</li>
          <li>LiqPay</li>
          <li>Оплат за реквізитами</li>
          <li>Оплати частинами через Monobank та ПриватБанк</li>
        </ul>
        <p class="text-block">До кінця перехідного періоду оплати приймаються як від клієнтів, так і від агентів, без обмежень за сумою.</p>
        <p>Прохання більше не сплачувати на ТОВ "ЮІНШУР"</p>
        <p>Укрсиббанку та на ФОП Ярова Олена Миколаївна.</p>
        <p><span class="red">Незабаром ці рахунки будуть закриті.</span></p>
        <p class="text-block">
          Також ми аналізуємо можливість отримання QR-кодів та терміналів (включаючи термінали в телефоні) для зручних оплат від клієнтів фізичних бізнесів. Тому повідомте, будь ласка, у свій чат, якщо для Вас це актуально.
        </p>
      </div>
      <div class="row bottom-block">
        <div class="col-12" align="center">
          <button class="btn btn-blue" style="display: block" @click="handleLinkClick()">
            Ознайомився(лась)
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookie from 'vue-cookie';

export default {
  name: "ModalBankDetailSub",
  props: {
  },
  data() {
    return {
      showPopupSub: false,
    };
  },
  mounted() {
    if(!this.isMainUser()){
      this.showPopupSub = !VueCookie.get("ModalBankDetailSubComleted");
    }
  },
  methods: {
    handleLinkClick() {
      this.showPopupSub = false;
      VueCookie.set("ModalBankDetailSubComleted", true, { expires: "1Y" });
    },
    isMainUser() {
      return !!! this.$store.getters.USER?.user_parent_id;
    },
  }
};
</script>


<style scoped>
.bank-detail.primary.popup-content{
  width: 446px;
  height: 455px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 111px;
}


.bank-detail.primary.popup-content .scroll-bar{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
  scrollbar-color: #cfcfcf white;
  scrollbar-width: thin;
}
.bank-detail.primary.popup-content p {
  text-align: justify;
}
.bank-detail.primary.popup-content p.title{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.bank-detail.primary.popup-content p {
  margin-bottom: 0px;
}
.bank-detail.primary.popup-content ul > li{
  text-align: justify;
}
.bank-detail.primary.popup-content span.red{
  color: red;
}
.bank-detail.primary.popup-content .text-block{
  margin-bottom: 10px;
}
.bank-detail.primary.popup-content .text-block.bold{
  font-weight: bold;
}
.bank-detail.primary.popup-content .custom-list {
  list-style-type: none; /* Убираем стандартные маркеры */
  padding-left: 20px; /* Отступ для маркеров */
}

.bank-detail.primary.popup-content .custom-list li {
  position: relative;
  padding-left: 20px; /* Отступ для ромбика */
}
.bank-detail.primary.popup-content .custom-list.romb li::before {
  content: "◆";
  color: blue;
  font-size: 1.2em;
  position: absolute;
  left: 0;
  top: 0;
}
.bank-detail.primary.popup-content .custom-list.ok li::before {
  content: "✅";
  color: green;
  font-size: 0.8em;
  position: absolute;
  left: 0;
  top: 0;
}
.bank-detail.primary.popup-content .btn{
  display: block;
  width: 100%;
  margin-top: 20px;
  border-color: transparent;
}

.bank-detail.primary.popup-content .btn:hover{
  background: inherit;
  color: inherit;
  border-color: grey;
  transition: border 0.5s ease-in-out;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-button {
  display: inline-block;
  margin: 10px 0;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.popup-button:hover {
  background: #0056b3;
}

.close-button {
  display: inline-block;
  margin: 10px 0;
  padding: 10px 20px;
  background: #6c757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}
</style>
